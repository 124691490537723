var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "register-container" },
    [
      _vm.step === 1
        ? _c(
            "div",
            [
              _c("div", { staticClass: "register-title" }, [
                _vm._v(" 川府农博店铺账号注册 "),
              ]),
              _c("div", { staticClass: "register-tip" }, [
                _vm._v(" 欢迎入驻 "),
              ]),
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入手机号" },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "验证码", prop: "code" } },
                    [
                      _c(
                        "div",
                        { class: [this.countDown !== 0 ? "get-coding" : ""] },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入验证码" },
                              model: {
                                value: _vm.form.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "code", $$v)
                                },
                                expression: "form.code",
                              },
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "get-code-container",
                                    on: { click: _vm.getCode },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.codeText) + " ")]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", prop: "agreement" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.agreement,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "agreement", $$v)
                            },
                            expression: "form.agreement",
                          },
                        },
                        [
                          _vm._v(" 我已仔细阅读并同意 "),
                          _c(
                            "span",
                            {
                              staticClass: "agreement",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.openAgreement.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("《商家入驻协议》")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible, top: "5vh" },
                  on: {
                    close: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_c("protocol")],
                1
              ),
              _c(
                "div",
                { staticClass: "open-shop-container" },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.submit },
                    },
                    [_vm._v("申请开店")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("verify", {
        ref: "verify",
        attrs: {
          mode: "pop",
          captchaType: "blockPuzzle",
          imgSize: { width: "300px", height: "150px" },
          explain: "向右滑动完成验证",
          mobile: _vm.form.mobile,
          scene: "SUPPLIER_REGISTER_NB",
        },
        on: { success: _vm.verifySuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }